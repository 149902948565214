import "../styles/globals.css";
import "../styles/home.css";
import "../styles/cursor.css";
import "../styles/navbar.css";
import "../styles/projects.css";
import "../styles/splashScreen.css";
import type { AppProps } from "next/app";
import GoogleAnalytics from "@bradgarropy/next-google-analytics";

// Splash screen
if (typeof window !== "undefined") {
  const intro = document.querySelector(".intro");
  const logoSpan = document.querySelectorAll(".logo");
  document.addEventListener("DOMContentLoaded", () => {
    setTimeout(() => {
      logoSpan.forEach((span, i) => {
        setTimeout(() => {
          span.classList.add("active");
        }, (i + 1) * 400);
      });

      setTimeout(() => {
        logoSpan.forEach((span, i) => {
          setTimeout(() => {
            span.classList.remove("active");
            span.classList.add("fade");
          }, (i + 1) * 50);
        });
      }, 2000);

      setTimeout(() => {
        (intro as HTMLElement).style.top = "-100vh";
      }, 2300);
    });
  });
}

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Component {...pageProps} />
      <GoogleAnalytics measurementId="G-JXCQH526TH" />
    </>
  );
}

export default MyApp;
